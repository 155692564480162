// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
    openid: "/api/openid",
    jsSign: "/api/jssign",
    jssdk: "/api/jssdk",
    user: {
        code: "/admin/sms",
        login: "/admin/login"
    },
    uploads: {
        uploadFile: "/api/upload_file",
        uploadBase64: "/api/uploadbase64",
        video:"/api/upload_file_voice"
    },
    admin: {
        doctorList: "/admin/doctor_list",
        meetingCount: "/admin/detail",
        doctorInfo: "/admin/doctor_info",
        doctorMeetingList: "/admin/doctor_meet_list",
        meet: "/admin/meet",
        newMeet: "/admin/new_meet",
        meetDetail: "/doctor/meet_detail",
        meetSign: "/admin/meet_sign",
        meetError: "/admin/meet_error",
        getCourseInfo: "/api/class_info",
        auditStatus: "/admin/audit_status",
        project: "/api/project_detail",
        admin_share: "/api/admin_send_ppt",
        preview: '/api/doctor_page',
        myProject: "/admin/admin_project"
    },
    role: {
        doctor_period:"/kclass_roles/doctor_period"
    },
    doctor:{
        meetList:"/doctor/meet_list",
        historyMeetList:"/doctor/history_meet_list",
        doctorInfo:"/doctor/info",
        register:"/doctor/register",
        hospitals:"/api/hospital",
        departments:"/api/department",
        meetSign:"/api/sign_meet",
        agreementField:"/api/agreement_field",
        hasPage:"/api/has_page",
        setEssence:"/view_app/set_essence",
        image:"/admin/doctor_profile"
    },
    meet: {
        sign: "/api/sign_live",
        fastReply: "/api/custom_question",
        reset: "/api/meet_reset",
        customQuestion: "/api/courseware_content",
        pageContent: '/api/custom_content',
        upload: "/api/upload_many_files",
        uploadWeixin: '/api/upload_wx_image',
        project:"/kclass_meet/meet_project"
    },
    api: {
        project: "/api/project_list",
        courseware: "/api/class_list",
        meetDetail: "/api/meet_detail",
        sendEmail: "/api/class_send",
        pptxDownload: "/api/send_ppt" //幻灯提词下载
    },
    article: {
        articleList: '/kclass_meet/get_article_list',//列表
        articleDetail: '/kclass_meet/article',//详情
        articleHelp: '/kclass_meet/article_help',//原文求助
        chooseArticle: '/kclass_meet/choose_article'//选择该文章post 是否选择get
    },
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
    if (typeof urls[key] == "object") {
        for (let i in urls[key]) {
            urls[key][i] = `${host}${urls[key][i]}`;
        }
        continue
    }
    urls[key] = `${host}${urls[key]}`;
}
export default urls;
